@font-face {
  font-family: "Quicksand";
  src: local("Quicksand-Regular"), url("./fonts/Quicksand-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand";
  src: local("Quicksand-SemiBold"), url("./fonts/Quicksand-SemiBold.ttf") format("truetype");
  font-weight:600;
  font-style: normal;
}

html, body, #root {
  margin: 0;
  padding: 0;
  top:0; right:0; bottom:0; left:0;
  width:100%;
  height: 100%;
  box-sizing: border-box;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Quicksand", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* touch-action: none; */

  --color-brand: #161F3D;
  --color-bg: #F5F5F5;
  --color-alt-bg: white;
  --color-primary: var(--color-brand);
  --color-secondary: #6CAEA8;
  --color-text-primary: #000;
  --color-text-secondary: #333;
  --color-error: red;
  --color-success: green;


  background-color: var(--color-bg);
  color: var(--color-text-primary);

  --shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  
  overflow-y: scroll;
  /* overflow-x: hidden; */
}

h1 {
  font-weight: 600;
}

*:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.hide {display: none;}

.text-title {
  font-size: 3em;
  font-weight: 600;
  color: var(--color-brand);
  justify-content: center;
}

.text-bold {
  font-weight: 600;
}

.text-success {
  color: var(--color-success);
}

.text-right {
  text-align: right;
}

.btn {
  color: var(--color-brand);
  background-color: var(--color-bg);
  border:none;
}

.btn:hover, .btn:active {
  color: var(--color-brand);
  background-color: var(--color-bg);
  border:none;
}

.btn-toggle {
  color: var(--color-brand);
  background-color: var(--color-bg);
  border:none;
  border-radius: 5px;
}

.btn-toggle:hover:not(.toggle-selected), .btn-toggle:active:not(.toggle-selected) {
  background-color: var(--color-brand);
}

.toggle-selected i {
  color: var(--color-secondary)
}

.btn-toggle:hover:not(.toggle-selected) i, .btn-toggle:active:not(.toggle-selected) i {
  color: var(--color-alt-bg);
}

.btn-primary {
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
  border-radius: 5px;
  background-color: var(--color-bg);
  font-weight: 600;
}

.btn-primary:hover, .btn-primary:active {
  color: white;
  background-color: var(--color-primary);
}

.btn-error {
  color: var(--color-error);
  border: 2px solid var(--color-error);
  background-color: var(--color-alt-bg);
  border-radius: 5px;
  font-weight: 600;
}

.btn-error:hover, .btn-error:active {
  background-color: var(--color-error);
  color: white;
}

.btn-cancel, .btn-secondary {
  color: var(--color-secondary);
  border: 2px solid var(--color-secondary);
  background-color: var(--color-bg);
  border-radius: 5px;
  font-weight: 600;
}

.btn-cancel:hover, .btn-secondary:hover, .btn-cancel:active, .btn-secondary:active {
  background-color: var(--color-secondary);
  color: white;
}

input[type=checkbox] {
  accent-color: var(--color-brand);
}

.color-error, .text-error {
  color: var(--color-error);
}

.success-message {
  color: var(--color-success);
}

/* ERROR PAGE */
.error-page-message {
  font-size: 1.5em;
  color: var(--color-error);
  font-weight: 600;
}

/* ICONS */

.toggle-icon {
  color: var(--color-brand);
}

.icon-checked {
  color: var(--color-secondary);
}

.icon-success {
  color: var(--color-success);
}

.icon-error {
  color: var(--color-error);
}

.room-detail-cleaning-status-icon i {
  vertical-align: middle;
}



@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

/* 404 */
.not-found, .maintenance {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--color-brand);
  text-align: center;
}

.not-found img, .maintenance img {
  width: 10%;
  min-width: 100px;
}

.not-found h1 {
  color: var(--color-alt-bg);
  font-weight: 600;
  font-size: 4em;
}

.maintenance h1 {
  color: var(--color-alt-bg);
  font-weight: 600;
  font-size: 2em;
}

.maintenance p {
  color: var(--color-alt-bg);
}

/* LOGIN PAGE */
.login-description {
  font-size: 1.5em;
  color: var(--color-text-secondary);
}

.home-form a, .login-form a {
  text-decoration: none;
  color: var(--color-text-secondary);
}

.home-form a:hover, .home-form a:active,
.login-form a:hover, .login-form a:active {
  font-weight: 600;
}

.login-form, .home-form {
  justify-content: center;
  display: flex;
  align-items: center;
  height:95%;
  overflow-y: scroll;
  /* width:50%; */
}

.login-form form {
  width: 60%;
}

@media screen and (max-width: 767px) {
  .login-form form {
    width: 100%;
  }
}

.login-form input, .home-form input {
  border-radius: 15px;
  border:2px solid var(--color-primary);
  text-align: center;
  font-size: 1.1em;
}

.home-form input {
  font-size:1.5em;
}

.btn-login {
  background-color: var(--color-primary);
  color: white;
  width:100%;
  border-radius:15px;
  font-size: 1.5em;
}

.btn-login:hover {
  background-color: var(--color-primary);
  color: white;
  opacity: 0.8;
}

.login-form-error-msg {
  color: var(--color-error);
}

/* CLEANING PAGE */

.cleaning-page {
  width:100%;
  max-width: 500px;
  padding:0;
  background-color: var(--color-bg);
}

.cleaning-page-header {
  background-color: var(--color-bg);
  height: 200px;
  width:100%;
  max-width: 500px;
  z-index: 1;
}

.cleaning-page-list {
  margin-top: 200px;
  height: 100%;
}

#cleaning-page-site-name {
  font-size: 1.75em;
  background-color: var(--color-bg);
}

.cleaning-page-list-title {
  font-weight: 600;
  color: var(--color-brand);
}

.cleaning-page-task {
  width:100%;
  border-radius: 10px;
  position: relative;
  background-color: var(--color-alt-bg);
  box-shadow: var(--shadow);
  /* max-width: 400px; */
}

.cleaning-page-task-room-name {
  font-size: 2em;
}

.cleaning-page-status-button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.5em;
}

.cleaning-page-status-button > button, .cleaning-page-task-alert > button {
  border: none;
  background: none;
  border-radius:50%;
}

.fa-circle-check {
  color: var(--color-success);
}

.fa-circle-minus {
  color: var(--color-error);
}

.view-alert-modal-open-icon > .fa-circle-exclamation {
  color: var(--color-brand);
}

.fa-plus {
  color: var(--color-brand);
}

.cleaning-page-task-alert > .fa-plus:hover {
  color: var(--color-brand)
}

.cleaning-page-task-alert {
  position: absolute;
  bottom:0; 
  right:0;
  font-size: 1.5em;
}

.cleaning-page-task-send-back {
  color: var(--color-error);
  font-weight: 600;
  display: flex;
  align-items: center;
}

.sendback {
  border: var(--color-error) solid 3px;
}

.cleaning-page-filters label {
  display: inline-block;
  border: none;
  text-align: center;
}

.cleaning-page-filters input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + label {
  color: white;
  background-color: var(--color-brand);
  border-radius:5px;
}

.cleaning-page-filter-option {
  width:30%;
}

.modal, .modal-header, .modal-body, .modal-footer {
  background-color: var(--color-bg);
  border: none;
}

.modal-close {
  color: var(--color-brand);
}

.create-alert-modal-description {
  font-size: 1em;
}

.create-alert-modal-error-message {
  color: var(--color-error);
}

.create-alert-modal-success-message {
  color: var(--color-success);
}

.view-alert-modal-subtext {
  font-size: 0.75em;
  color: var(--color-text-secondary);
}

/* DASHBOARD */

#sidebar {
  width: 4.5rem;
  height:100%;
  position: fixed;
  top:0;
  left:0;
  background-color: var(--color-brand);
  color:white;
  border: none;
  overflow-y: scroll;
}

#dashboard-content {
  width:calc(100%-4.5em);
  height: 100%;
  margin-left: 4.5rem;
  box-sizing: border-box;
}

.navigation-item {
  text-align: center;
  align-items: center;
  width:100%;
  color:white;
}

.navigation-item a {
  text-decoration: none;
  color:white;
}

.navigation-item a:hover:not(.active) {
  color:var(--color-secondary);
  text-decoration: none;
}

.navigation-logo img {
  width:100%;
  padding:10px
}

@media screen and (max-width: 767px) {
  #sidebar {
    width: 100%;
    height: 60px;
    position: relative;
    background-color: var(--color-brand);
  }
  .navigation-item {float: left; display:inline; width:20%; background-color: var(--color-brand);}
  #dashboard-content {margin-left: 0; width:100%}
  .navigation-logo {display:none;}
}

.dropdown-toggle {
  background-color: var(--color-bg);
  border:none;
  font-size: 1.5em;
  color: var(--color-brand);
  font-weight: 600;
}

.property-dropdown {
  justify-content: center;
}

.dropdown-menu {
  background-color: var(--color-alt-bg);
}

.dropdown-item {
  font-size:1.25em;
  font-weight: 600;
}

.dropdown-item:hover, .dropdown-item:active {
  background-color: var(--color-alt-bg);
  color: var(--color-secondary);
}

.search-wrapper {
  position: relative;
}

.search-bar {
  display: inline-block;
  margin-left: auto;
  /* margin-right: 5%; */
  height: 100%;
  font-size: 1.25em;
}

.header-row {
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 767px) {
  .search-bar {margin: 0;}
  .search-wrapper {width:100%;}
}

.search-input {
  border:none;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background-color: var(--color-alt-bg);
  box-shadow: var(--shadow);
  width:350px;
}

.search-results {
  position: absolute;
  font-size: 1.25em;
  margin-right:0%;
  margin-top:67px;
  z-index:1;
  right:0;
  top: 0;
  width: 350px;
  list-style: none;
  background-color: var(--color-alt-bg);
  border-radius:10px;
  max-height: 200px;
  overflow-y: scroll;
  box-shadow: var(--shadow);
}

.search-result {
  border-bottom: solid 1px var(--color-bg);
}

.search-result-cleaning-status-icon {
  float: right;
}

.status-clean {
  color: var(--color-success);
}

.status-dirty {
  color: var(--color-error);
}

@media screen and (max-width: 767px) {
  .search-bar {
      width:100%;
  }

  .search-input {
      width: 100%;
      padding: 0 5%;
  }

  .search-results {
      width: 98%;
      /* margin-top: 2px; */
      margin: 60px 1%;
      max-height: 200px;
  }

}

/* DASHBOARD OVERVIEW */

.dashboard-title {
  color: var(--color-brand);
}

.metric {
  width:22.5%;
  padding:15px;
  background-color: var(--color-alt-bg);
  box-shadow: var(--shadow);
  border-radius: 10px;
  text-align: center;
  min-height: 20vh;
  min-width: 225px;
}

@media screen and (max-width: 767px) {
  .metric {width:100%;}
}

.metric-large {
  width:48%;
  padding:15px;
  background-color: var(--color-alt-bg);
  box-shadow: var(--shadow);
  border-radius: 10px;
  text-align: left;
  min-height: 50vh;
  min-width: 300px;
  overflow-y: scroll;
}

.metric-description {
  font-size: 1.2em;
}

.metric-value {
  font-size: 2.5em;
  color: var(--color-brand);
  font-weight: 600;
  padding-bottom: 10px;
}

.metric-large-title {
  font-weight: 600;
  font-size: 1.2em;
}

/* DASHBOARD TASKS */

.date-selector-date {
  font-size: 1.4em;
  font-weight: 600;
  /* border: 1px solid var(--color-brand);
  border-radius: 10px; */
  padding:2%;
  align-items: baseline;
}

.date-selector {
  align-items: baseline;
}

.react-datepicker-wrapper {
  width: 200px !important;
  text-align: center;
}

.glan-datepicker-calendar .react-datepicker__header {
  background-color: var(--color-bg);
}

.glan-datepicker-calendar .react-datepicker__month {
  background-color: var(--color-alt-bg);
}

.glan-datepicker-calendar .react-datepicker__day:hover {
  background-color: var(--color-bg);
}

.glan-datepicker-calendar .react-datepicker__day--selected {
  background-color: var(--color-brand);
  color: var(--color-secondary);
  font-weight: 600;
}

.list {
  height:100%;
  overflow-y:scroll;
  /* width:100%; */
}

.lists {
  height: 70%;
}

.list-header {
  font-size: 1.3em;
  font-weight: 600;
}
.selected-item-wrapper {
  position: relative;
}

.selected-item {
  background-color: var(--color-alt-bg);
  box-shadow: var(--shadow);
  border-radius:10px;
  position: absolute;
  top: 0;
  right:0;
  width:100%;
}

.selected-task {
  border: 3px solid var(--color-secondary);
}

@media screen and (max-width: 767px) {
  .selected-task {
      border: none;
  }
}

.selected-cleaning-task-id {
  font-size: 1.75em;
  font-weight: 600;
  color:var(--color-brand);
}

.selected-cleaning-task-sendback-button {
  position: absolute;
  bottom:0;
  right:0;
}

.rooms {
  overflow-y: scroll;
  height: 75%;
}

.add-task-icon {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.add-task-icon:hover i, .add-task-icon:active i {
  color: white;
}

.create-new-task-modal, .view-alert-modal, .create-alert-modal {
  position: absolute;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: var(--color-bg);
}

.modal-close-button {
  position: absolute;
  top: 0;
  right:0;
}

/* .create-new-task-form {
  width: 100%;
  max-width: 300px;
} */

.modal-container {
  width:100%;
  max-width: 500px;
  position: relative;
}

.modal-error-message {
  color: var(--color-error);
}

/* DASHBOARD: ROOM DETAIL */


.room-detail-room-name {
  display: inline-block;
  font-size: 2em;
}

.room-detail-icons {
  float: right;
}

.room-detail-cleaning-status {
  float:right;
  font-size: 2em;
}

.room-detail-icons i {
  font-size: 2em;
}

.room-detail-cleaning-tasks-panel, .room-detail-alerts-panel {
  width: 100%;
  height: 100%;
  background-color: var(--color-alt-bg);
  border-radius: 10px;
  box-shadow: var(--shadow);
  position: relative;
}

.panel-container {
  height: 75%;
}

.panel {
  width: 48%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .panel{
      width: 100%;
  }
}

.panel-title {
  font-weight: 600;
  font-size: 1.5em;
}

.panel-list {
  height: 80%;
  overflow-y: scroll;
}

.panel-list-item {
  border-radius: 10px;
  box-shadow: var(--shadow);
  width: 98%;
  position:relative;
}

.room-detail-cleaning-tasks-panel-last-cleaned, .room-detail-cleaning-tasks-panel-total-tasks, .room-detail-alerts-panel-total-alerts {
  color: var(--color-text-secondary);
  font-size: 0.8em;
}

.room-detail-cleaning-task-panel-status, .room-detail-alerts-panel-status {
  position: absolute;
  top: 0;
  right:0;
}

.room-detail-cleaning-task-panel-sendback {
  position: absolute;
  bottom: 0;
  right: 0;
}

/* DASHBOARD: ALERTS */

.alerts-table-date, .alerts-table-status {
  white-space: nowrap;
}

.dashboard-alerts-filters label {
  display: inline-block;
  border: none;
  text-align: center;
}

.dashboard-alerts-filters input[type="radio"] {
  display: none;
}

.dashboard-alerts-filter-option {
  width: 10%;
}

@media screen and (max-width: 767px) {
  .dashboard-alerts-filter-option{
      width: 30%;
  }
}

.alerts {
  overflow-y: scroll;
  height: 70%;
}

.error-message { 
  display: inline-block;
  color: var(--color-error)
}

.action-buttons {
  display: inline-block;
}

/* DASHBOARD: TASKS - TABLE */
.tasks-table {
  height: 85%;
  overflow-y: scroll;
}

@media screen and (max-width: 767px) {
  .sendback-task-mobile {
      color: var(--color-error);
  }
}

.view-toggler {
  align-items: center;
}

.filter {
  display: inline;
  margin:0;
  padding:0;
}

.btn-filter {
  margin:0;
  padding:0;
}

.btn-filter i {
  font-size: 0.8em;
}

.filter-active{
  color: var(--color-secondary);
}

th {
  vertical-align: middle;
}

/* DASHBOARD: ALERTS DETAIL */

.alert-detail-panel {
  width: 50%;
  background-color: var(--color-alt-bg);
  height: 70%;
  border-radius: 10px;
  box-shadow: var(--shadow);
  overflow-y: scroll;
  position: relative;
}

@media screen and (max-width: 767px) {
  .alert-detail-panel {
      width: 98%;
  }
}

.alert-detail-close-button {
  position:absolute;
  top:0;
  right:0;
}

.alert-detail-close-button button {
  background-color: var(--color-alt-bg);
}

.alert-detail-room {
  font-size: 1.5em;
  font-weight: 600;
}

.alert-detail-description {
  font-size: 1.2em;
}

.alert-detail-description-title { 
  display: block;
  font-weight: 600;
}

.alert-detail-created-date, .alert-detail-closed-date {
  color: var(--color-text-secondary);
}


/* SIGNUP - LOGIN */

.form-text {
  font-size: 0.7em;
  color: var(--color-text-secondary)
}

/* DASHBOARD - REPORTS */

.report-panel {
  background-color: var(--color-alt-bg);
  border-radius: 10px;
  box-shadow: var(--shadow);
  
  overflow-y: hidden;
}

.report-panel-title {
  font-size: 1.25em;
  font-weight: 600;
}

.report-panel-description {
  font-size: 0.8em;
  color: var(--color-text-secondary);
}

.report-panel-content {
  width: 100%;
}

.report-panel-data {
  overflow-y: scroll;
  min-height: 100px;
  max-height: 450px;
}

.two-thirds {
  width: 65%;
}

.one-third {
  width: 33%;
}

@media screen and (max-width: 767px) {
  .two-thirds, .one-third {
      width: 98%;
  }
}
